footer {
  bottom: 35px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

div.footer-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

footer > p {
  text-align: center;
  font-size: 80%;
}

.footer-icon {
  margin: 1.2rem;

  @media all and (max-width: $on-medium) {
    margin: 0.8rem;
  }
}