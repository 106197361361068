.sharing {
  display: inline-flex; 
  align-items: center;
}

.sharing-icon {

  width: 1.2em;
  height: 1.2em;
  margin: 0rem 0.6rem;
}